<!--
 * @Author: lzh
 * @Date: 2022-07-05 18:06:56
 * @LastEditors: lbh
 * @LastEditTime: 2023-11-29 14:11:35
 * @Description: file content
-->
<template>
  <div
    class="offer-box px-offer-box"
    @click="clickItem('title')"
    :class="`edit_${Xindex}_title`"
  >
    <div class="top-title">
      <h1
        class="title"
        v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      ></h1>
      <h2
        class="desc"
        v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      ></h2>
    </div>
    <div class="content">
      <div class="left-box">
        <div
          class="title"
          v-html="$util.rex.getHtml(configs.itemData[`${nowL}title`] || configs.itemData.title)"
        ></div>
        <div class="item-box">
          <div
            class="item"
            v-for="(item, index) in configs.itemData.descObj"
            :key="index"
            :style="index == 1 ? 'margin-left:30px;' : ''"
          >
            <img
              class="img"
              :src="item.img"
            />
            <span
              class="text"
              v-html="$util.rex.getHtml(item[`${nowL}text`] || item.text)"
            ></span>
          </div>
        </div>
        <div class="icon-box">
          <div
            class="item"
            :style="index == 0 ? 'margin-left:0;' : ''"
            v-for="(item, index) in configs.itemData.icons"
            :key="index"
          >
            <img
              class="img"
              :src="item.img"
            />
            <div
              class="title"
              v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
            ></div>
          </div>
        </div>
        <selfButton
          v-if="configs.itemData && configs.itemData.btn && configs.itemData.btn.title"
          :go="configs.itemData.btn.go"
          class="btn"
        >{{configs.itemData.btn[`${nowL}title`] || configs.itemData.btn.title}}</selfButton>
      </div>
      <div class="right-box">
        <img
          class="img"
          :src="configs.itemData.img"
          :alt="configs.itemData.alt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  mixins: [langMixin],
  name: 'self-offer',
  props: {
    configs: {
      default () {
        return {
          title: '',
          desc: '',
          itemData: {
            title: '',
            descObj: [],
            icons: [],
            btnText: '',
            img: '',
          },
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: '',
      showOffer: false,
    };
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.offer-box {
  padding: 70px 260px;
  padding-bottom: 90px;
  background: #ffffff;
  .top-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 30px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .desc {
      font-size: 20px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
  .content {
    box-shadow: 4px 4px 50px 1px rgba(0, 0, 0, 0.04),
      -4px -4px 50px 1px rgba(0, 0, 0, 0.04);
    border-radius: 20px 20px 20px 20px;
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 90px 80px;
    .left-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      .title {
        font-size: 40px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .item-box {
        display: flex;
        align-items: center;
        .item {
          display: flex;
          align-items: baseline;
          margin-top: 20px;
          .img {
            width: 24px;
            transform: translateY(4px);
          }
          .text {
            margin-left: 4px;
            font-size: 24px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
        .item_1 {
          margin-left: 30px;
        }
      }
      .icon-box {
        margin-top: 50px;
        display: flex;
        // align-items: center;
        justify-content: space-between;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 20px;
          .img {
            width: 90px;
          }
          .title {
            font-size: 20px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-top: 4px;
            text-align: center;
          }
        }
      }
      .btn {
        margin-top: 30px;
      }
    }
    .right-box {
      padding-left: 90px;
      width: 40%;
      .img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .px-offer-box {
    padding: 25px 30px;
    .top-title {
      .title {
        font-size: 15px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      .desc {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
    .content {
      position: relative;
      padding: 30px;
      margin-top: 25px;
      .left-box {
        .title {
          font-size: 15px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
        }
        .item-box {
          .item {
            .img {
              width: 10px;
              height: 10px;
              margin-right: 3px;
            }
            .text {
              font-size: 10px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }
        }
        .icon-box {
          // width: 50%;
          // height: 80px;
          .item {
            margin-left: 10px;
            .img {
              width: 23px;
              height: 23px;
            }
            .title {
              font-size: 7px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-top: 4px;
            }
          }
        }
        .btn {
          margin-top: 10px;
        }
      }
      .right-box {
        // position: absolute;
        padding: 0;
        right: 30px;
        bottom: 10px;
        .img {
          vertical-align: middle;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .px-offer-box {
    padding: 25px 14px;
    .top-title {
      .title {
        font-size: 15px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      .desc {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
    .content {
      position: relative;
      padding: 16px 10px;
      .left-box {
        .title {
          font-size: 15px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
        }
        .item-box {
          .item {
            font-size: 0;
            .img {
              width: 10px;
              height: 10px;
              font-size: 0;
              margin-right: 3px;
            }
            .text {
              font-size: 10px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }
        }
        .icon-box {
          width: 50%;
          // height: 80px;
          margin-top: 20px !important;
          margin-bottom: 20px !important;
          .item {
            font-size: 0;
            .img {
              width: 23px;
              font-size: 0;
              height: 23px;
            }
            .title {
              font-size: 6px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-top: 4px;
            }
          }
        }
        .btn {
          margin-top: 0px;
        }
      }
      .right-box {
        position: absolute;
        padding: 0;
        right: 10px;
        bottom: 16px;
        .img {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
